import { BigNumber } from 'bignumber.js'
import {
  OtherPriceDto,
  OtherPriceDtoTypeEnum,
  QuotationItemPriceAutoDto,
  QuotationItemPriceDto,
  QuotationItemPriceFixedDto,
  RoundingSettings,
  RoundingSettingsRoundingTypeEnum,
} from '~/remote/api-spec'
import Quotation from '~/models/Quotation'

export function defaultDiscount(): OtherPriceDto {
  return {
    type: OtherPriceDtoTypeEnum.SpecialDiscount,
    description: '',
    amountPurchase: 0,
    amountSelling: 0,
    display: true,
  }
}
export function defaultPriceSettings(): RoundingSettings {
  return {
    percent: 1,
    roundingStep: 10,
    roundingType: RoundingSettingsRoundingTypeEnum.Up,
  }
}

function applyRounding(
  value: BigNumber,
  rounding: RoundingSettings
): BigNumber {
  const step = new BigNumber(rounding.roundingStep)
  const applied = value.dividedBy(step)

  switch (rounding.roundingType) {
    case RoundingSettingsRoundingTypeEnum.Up:
      return applied.decimalPlaces(0, BigNumber.ROUND_CEIL).multipliedBy(step)
    case RoundingSettingsRoundingTypeEnum.Down:
      return applied.decimalPlaces(0, BigNumber.ROUND_FLOOR).multipliedBy(step)
    default:
      return applied
        .decimalPlaces(0, BigNumber.ROUND_HALF_UP)
        .multipliedBy(step)
  }
}

/** 標準価格：標準仕切り ÷ 定価率 = 標準価格 **/
function computeStandardPrice(
  standardPartition: BigNumber,
  settings: QuotationItemPriceAutoDto
): BigNumber {
  const rounding = settings.fixedPriceSettings
  if (rounding.percent === 0) {
    return new BigNumber(0)
  }
  return applyRounding(standardPartition.dividedBy(rounding.percent), rounding)
}

/** 販売価格：標準価格 × 販売掛率 = 販売価格 */
function computeSellingPrice(
  standardPrice: BigNumber,
  settings: QuotationItemPriceAutoDto
): BigNumber {
  const rounding = settings.salesPriceSettings
  return applyRounding(standardPrice.multipliedBy(rounding.percent), rounding)
}

/** 合計金額：（販売単価 × 台数) */
function computeTotalAmount(
  sellingPrice: BigNumber,
  quantity: number
): BigNumber {
  return sellingPrice.multipliedBy(quantity)
}

/** 仕入価格：標準仕切り × 仕入掛率 × 台数 */
function computePurchasePrice(
  standardPartition: BigNumber,
  quantity: BigNumber,
  settings: QuotationItemPriceAutoDto
): BigNumber {
  const rounding = settings.purchasePriceSettings
  return applyRounding(
    standardPartition.multipliedBy(rounding.percent),
    rounding
  ).multipliedBy(quantity)
}

/** 利益：合計金額 - 仕入価格 = 利益 */
function computeProfit(
  totalAmount: BigNumber,
  purchasePrice: BigNumber
): BigNumber {
  return totalAmount.minus(purchasePrice)
}

/** 利益率：利益 ÷ 1台金額 × 100 = 利益率 */
function computeProfitMargin(
  profit: BigNumber,
  totalAmount: BigNumber
): BigNumber {
  if (totalAmount.isZero()) {
    return new BigNumber(0)
  }

  return profit
    .dividedBy(totalAmount)
    .multipliedBy(100)
    .decimalPlaces(4, BigNumber.ROUND_HALF_UP)
}

export interface CostInformation {
  /** Given: Amount of items */
  quantity: number

  /**
   * Base price of the item
   *
   * Auto: Given
   * Fixed: N/A
   */
  basePrice: number

  /**
   * Base price of the item without margins
   *
   * Auto: Computed
   * Fixed: N/A
   */
  standardPrice: number

  /**
   * Purchase price of a single item without margin or extra prices
   *
   * Auto: Computed
   * Fixed: Given
   */
  purchasePrice: number

  /**
   * Selling price of a single item without margin or extra prices
   *
   * Auto: Computed
   * Fixed: Given
   */
  sellingPrice: number

  /**
   * Selling price of a single item with margin and all extra prices
   *
   * Auto: Computed
   * Fixed: Computed
   */
  unitPrice: number

  /**
   * Selling price of all items without margin, no extra prices
   *
   * Auto: Computed
   * Fixed: Computed
   */
  totalAmount: number

  /**
   * Profit of all items with margin and all extra prices
   *
   * Auto: Computed
   * Fixed: Computed
   */
  profit: number

  /**
   * Profit margin of all items with margin and all extra prices
   *
   * Auto: Computed
   * Fixed: Computed
   */
  profitMargin: number

  /**
   * Purchase price of all items without margin or visible extra price
   * For hidden extra price on the item, this value will change
   *
   * Auto: Computed
   * Fixed: Computed
   */
  netPurchasePrice: number

  /**
   * Selling price of a single item without margin or visible extra price
   * For hidden extra price on the item, this value will change
   *
   * Auto: Computed
   * Fixed: Computed
   */
  netSellingPrice: number

  /**
   * Selling price of all items with margin and all extra prices
   *
   * Auto: Computed
   */
  netTotalAmount: number

  rawSellingPrice: number
}

function recomputeProfit(rawCosts: CostInformation): CostInformation {
  rawCosts.profit = computeProfit(
    new BigNumber(rawCosts.netTotalAmount),
    new BigNumber(rawCosts.netPurchasePrice)
  ).toNumber()
  rawCosts.profitMargin = computeProfitMargin(
    new BigNumber(rawCosts.profit),
    new BigNumber(rawCosts.netTotalAmount)
  ).toNumber()

  return rawCosts
}

function computeItemAutoCost(
  price: QuotationItemPriceAutoDto,
  quantity: number
): CostInformation {
  const basePrice = new BigNumber(price.basePrice)
  const standardPrice = computeStandardPrice(basePrice, price)

  const purchasePrice = computePurchasePrice(
    basePrice,
    new BigNumber(quantity),
    price
  )

  const sellingPrice = computeSellingPrice(standardPrice, price)
  const totalAmount = computeTotalAmount(sellingPrice, quantity)

  return {
    quantity,
    basePrice: basePrice.toNumber(),
    standardPrice: standardPrice.toNumber(),
    purchasePrice: purchasePrice.toNumber(),

    rawSellingPrice: sellingPrice.toNumber(),
    sellingPrice: sellingPrice.toNumber(),
    unitPrice: sellingPrice.toNumber(),
    totalAmount: totalAmount.toNumber(),

    netPurchasePrice: -1,
    netSellingPrice: -1,
    netTotalAmount: -1,

    profit: -1, // Based on net values
    profitMargin: -1, // Based on net values
  }
}

function computeItemFixedCost(
  price: QuotationItemPriceFixedDto,
  quantity: number
): CostInformation {
  const purchasePrice = new BigNumber(price.purchasePrice).multipliedBy(
    quantity
  )

  const sellingPrice = new BigNumber(price.salePrice)
  const totalAmount = computeTotalAmount(sellingPrice, quantity)

  return {
    quantity,

    basePrice: -1,
    standardPrice: -1,
    purchasePrice: purchasePrice.toNumber(),

    rawSellingPrice: sellingPrice.toNumber(),
    sellingPrice: sellingPrice.toNumber(),
    unitPrice: sellingPrice.toNumber(),
    totalAmount: totalAmount.toNumber(),

    netPurchasePrice: -1,
    netSellingPrice: -1,
    netTotalAmount: -1,

    profit: -1, // Based on net values
    profitMargin: -1, // Based on net values
  }
}

function computeItemCostOnly(
  price: QuotationItemPriceDto,
  quantity: number
): CostInformation {
  if (!(price as QuotationItemPriceAutoDto).partName) {
    return computeItemFixedCost(price as QuotationItemPriceFixedDto, quantity)
  }
  return computeItemAutoCost(price as QuotationItemPriceAutoDto, quantity)
}

export function applySign(type: OtherPriceDtoTypeEnum, amount: number): number {
  if (amount <= 0) {
    return 0
  }

  switch (type) {
    case OtherPriceDtoTypeEnum.Discount:
      return -amount
    case OtherPriceDtoTypeEnum.SpecialDiscount:
      return -amount
    case OtherPriceDtoTypeEnum.Fixed:
      return amount
    case OtherPriceDtoTypeEnum.AdditionalCost:
      return amount
  }
  throw new Error('Unknown type: ' + String(type))
}

function divideByQuantity(value: number, quantity: number): number {
  return new BigNumber(value)
    .dividedBy(quantity)
    .decimalPlaces(0, BigNumber.ROUND_FLOOR)
    .toNumber()
}

function applyAdditionalCosts(
  rawCost: CostInformation,
  additionalCosts: OtherPriceDto[]
): CostInformation {
  const out: CostInformation = {
    ...rawCost,
  }

  if (out.netPurchasePrice < 0) {
    out.netPurchasePrice = out.purchasePrice
  }
  if (out.netSellingPrice < 0) {
    out.netSellingPrice = out.sellingPrice
  }
  if (out.netTotalAmount < 0) {
    out.netTotalAmount = out.totalAmount
  }

  for (const additional of additionalCosts) {
    out.netPurchasePrice += applySign(
      additional.type,
      additional.amountPurchase
    )

    const amountSelling = applySign(additional.type, additional.amountSelling)

    out.netSellingPrice += amountSelling
    out.netTotalAmount += amountSelling

    if (!additional.display) {
      out.sellingPrice += divideByQuantity(amountSelling, out.quantity)
    }
  }

  if (out.quantity < 2) {
    out.unitPrice = out.netTotalAmount
  } else {
    out.unitPrice = divideByQuantity(out.netTotalAmount, out.quantity)
  }

  recomputeProfit(out)

  return out
}

export function computeItemCost(
  price: QuotationItemPriceDto,
  quantity: number
): CostInformation {
  return applyAdditionalCosts(
    computeItemCostOnly(price, quantity),
    price.additionalCosts
  )
}

function computeQuotationCostOnly(quotation: Quotation): CostInformation {
  const out: CostInformation = {
    quantity: 1,

    basePrice: -1,
    standardPrice: -1,
    purchasePrice: 0,

    rawSellingPrice: 0,
    sellingPrice: 0,
    unitPrice: 0,
    totalAmount: 0,

    profit: 0,
    profitMargin: 0,

    netSellingPrice: 0,
    netPurchasePrice: 0,
    netTotalAmount: 0,
  }

  for (const item of quotation.items) {
    const cost = computeItemCost(item.partPrice, item.details.quantity)

    out.purchasePrice += cost.purchasePrice

    out.rawSellingPrice += cost.rawSellingPrice
    out.sellingPrice += cost.sellingPrice
    out.unitPrice += cost.unitPrice
    out.totalAmount += cost.totalAmount

    out.netPurchasePrice += cost.netPurchasePrice
    out.netSellingPrice += cost.netSellingPrice
    out.netTotalAmount += cost.netTotalAmount
  }

  return recomputeProfit(out)
}

export function computeQuotationCost(quotation: Quotation): CostInformation {
  return applyAdditionalCosts(
    computeQuotationCostOnly(quotation),
    quotation.additionalCosts
  )
}

export const _testExport = {
  applyRounding,
  applyAdditionalCosts,
  computeItemCostOnly,
  computeQuotationCostOnly,
}
